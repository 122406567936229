import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftVisuraPRA, RightVisuraPRA } from '../../components/pratiche-auto/visura-pra/VisuraPRA';

const VisuraPRA = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="duplicato-du" title="Visura PRA">
                <LeftVisuraPRA/>
                <RightVisuraPRA/>
            </ContentPratica>
        </Layout>
    )
}

export default VisuraPRA
